<template>
  <ul class="holdList">
    <li v-for="data in lists" :key="data.category_id">
      <router-link :to="{name: 'BrandItemsCategory', params: {code: brandCode, category: idCategories[data.category_id].code}}">
        <span class="holdListTxt">{{idCategories[data.category_id].name}}</span>
        <span class="arrow"><font-awesome-icon icon="chevron-right" /></span>
      </router-link>
    </li>
  </ul>
</template>

<script>
import {Mixin} from '@/mixins/common'
import axios from 'axios';
import { mapActions } from "vuex"

export default {
  props: ['brandCode'],
  data() {
    return {
      lists: {},
      title: '',
      description: ''
    };
  },
  mixins:[Mixin],
  components: {
  },
  created () {
    this.pageInit(this.$route)
  },
  mounted () {
  },
  head: {
    title: function () {
      return {
        inner: this.title
      }
    },
    meta: function () {
      return [
        { property: 'og:title', content: this.title, id: 'og-title'},
        { property: 'og:description', content: this.description, id: 'og-desc' },
        { name: 'description', content: this.description, id: 'meta-desc'},
      ]
    },
  },
  methods: {
    ...mapActions([
      'setshareData'
    ]),
    refresh() {
      this.lists = {}
      this.title = ''
      this.description = ''
    },
    pageInit(route) {
      this.refresh()
      this.getData()
    },
    getData(brandcode){
      const url = this.api + '/brands/' + this.brandCode + '/categories'

      axios.get(url)
      .then((res) => {
        this.lists = res.data.lists

        // meta
        const title = res.data.brand.brand_name_full + 'のアイテムカテゴリ一覧'
        this.title = title
        this.description = title + 'ページ。' + res.data.brand.brand_name_full + 'のアイテムをカテゴリから探すことができます。'
        this.$emit('updateHead')

        // sharedata
        const shareData = {}
        shareData.title = title
        shareData.text = title
        shareData.url = window.location.href
        this.setshareData(shareData)
      })
      .catch((e) =>{
        console.log(e);
        console.log('store data not found')
      })
    },
  },
}
</script>

<style scoped>
</style>
